import {
  ref, watch, computed, getCurrentInstance,
} from '@vue/composition-api'
import Vue from 'vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import loadingRegister from '@/libs/loading/loading'
import {
  trim,
  // setDatetimeDefault, handlerTimestamp
} from '@/libs/utils'

Vue.use(loadingRegister)

export default function useInvoicesList() {
  const { proxy } = getCurrentInstance()
  const { $store } = proxy
  // Use toast
  const toast = useToast()
  // 表格
  const refWithdrawListTable = ref(null)
  const tableColumns = [
    { key: 'transactionId', label: 'Transaction ID' },
    { key: 'amount', label: 'Amount' },
    { key: 'fee', label: 'Processing fee' },
    { key: 'currency', label: 'Currency' },
    { key: 'uuid', label: 'User ID' },
    { key: 'withdrawStatus', label: 'Withdraw status' },
    { key: 'withdrawChannel', label: 'Channel' },
    { key: 'partnerId', label: 'Channel ID' },
    // { key: 'partnerId', label: 'Channel ID', formatter: val => `$${val}` },
    { key: 'partnerStatus', label: 'Channel status' },
    { key: 'createdAt', label: 'Create time' },
    { key: 'updatedAt', label: 'Update time' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  // 筛选项
  const withdrawChannel = ref({ title: 'All', value: '-1' })
  const withdrawStatus = ref({ title: 'All', value: '-1' })
  const currency = ref({ code: 'BRL', name: 'BRL' })
  const timeType = ref({ label: 'CREATE', value: 'create' })
  const timeRange = ref([])
  const timeRangeDefault = ref([])
  // *取消默认时间*
  // timeRangeDefault.value = setDatetimeDefault(1, 1)
  // timeRange.value = handlerTimestamp(
  //   timeRangeDefault.value[0],
  //   timeRangeDefault.value[1],
  //   'America/Sao_Paulo',
  // )
  const mode = ref({ title: 'TRANSACTION ID', value: '1' })
  const searchKey = ref(null)

  // 时区 下载时用
  const timezone = ref('')
  // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
  const modeOptions = ref([
    { title: 'TRANSACTION ID', value: '1' },
    { title: 'USER ID', value: '3' },
    { title: 'CHANNEL ID', value: '2' },
    { title: 'PHONE', value: '4' },
    { title: 'DOCUMENT ID', value: '5' },
    { title: 'EMAIL', value: '6' },
  ])
  const withdrawChannelOption = ref([])
  const withdrawStatusOption = ref([])
  const withdrawList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refWithdrawListTable.value ? refWithdrawListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
      totalAmount: totalAmount.value,
    }
  })

  const refetchData = () => {
    refWithdrawListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  // 获取mode状态
  // const fetchModeOption = () => {
  //   store.dispatch('withdraw/fetchMode').then(response => {
  //     if (response.data.code == 200) {
  //       const { data } = response.data
  //       // eslint-disable-next-line no-use-before-define
  //       modeOptions.value = handlerFormatData(data)
  //       store.state.withdraw.modeOptions = modeOptions.value
  //     } else { }
  //   }).catch(error => {
  //     toast({
  //       component: ToastificationContent,
  //       props: {
  //         title: error.message,
  //         icon: 'AlertTriangleIcon',
  //         variant: 'danger',
  //       },
  //     })
  //   })
  // }
  // 获取渠道列表
  const fetchChannelOption = () => {
    $store.dispatch('withdraw/fetchChannel').then(response => {
      if (response.data.code == 200) {
        const { data } = response.data
        // eslint-disable-next-line no-use-before-define
        withdrawChannelOption.value = handlerFormatData(data)
        $store.commit('withdraw/updateWithdrawChannelOption', withdrawChannelOption.value)
      } else { }
    })
    // .catch(error => {
    //   toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: error.message,
    //       icon: 'AlertTriangleIcon',
    //       variant: 'danger',
    //     },
    //   })
    // })
  }
  // 获取钱包状态
  const fetchWalletStatusOption = () => {
    $store.dispatch('withdraw/fetchWalletStatus').then(response => {
      if (response.data.code == 200) {
        const { data } = response.data
        // eslint-disable-next-line no-use-before-define
        withdrawStatusOption.value = handlerFormatData(data)
        $store.state.withdraw.withdrawStatusOption = withdrawStatusOption.value
      } else { }
    })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const getWithdrawParams = () => {
    const params = {
      searchKey: trim(searchKey.value),
      model: Number(mode.value.value),
      withdrawStatus: Number(withdrawStatus.value.value),
      withdrawChannel: Number(withdrawChannel.value.value),
      startDate: null,
      endDate: null,
      pageNo: currentPage.value,
      pageSize: perPage.value,
      currency: currency.value.name,
    }
    if ((params.model === 4 || params.model === 5) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      if (timeType.value.value === 'create') {
        [params.startDate, params.endDate] = timeRange.value
        params.updatedAtBegin = null
        params.updatedAtEnd = null
      } else if (timeType.value.value === 'update') {
        [params.updatedAtBegin, params.updatedAtEnd] = timeRange.value
        params.startDate = null
        params.endDate = null
      }
    }
    return params
  }
  const fetchWithdrawList = () => {
    Vue.prototype.$showLoading()
    const params = getWithdrawParams()
    $store.dispatch('withdraw/fetchWithdrawList', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code == 200 && response.data.data) {
        const { list, page, extData } = response.data.data
        withdrawList.value = list
        totalInvoices.value = page.tatalCount
        totalAmount.value = extData.totalAmount
      } else if (response.data.code == 200 && !response.data.data) {
        withdrawList.value = []
        totalInvoices.value = 0
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const getDownloadParams = () => {
    const params = {
      searchKey: trim(searchKey.value),
      model: Number(mode.value.value),
      withdrawStatus: Number(withdrawStatus.value.value),
      withdrawChannel: Number(withdrawChannel.value.value),
      startDate: null,
      endDate: null,
      pageNo: currentPage.value,
      pageSize: perPage.value,
      timezone: timezone.value,
      currency: currency.value.name,
    }
    if ((params.model === 4 || params.model === 5) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      if (timeType.value.value === 'create') {
        [params.startDate, params.endDate] = timeRange.value
        params.updatedAtBegin = null
        params.updatedAtEnd = null
      } else if (timeType.value.value === 'update') {
        [params.updatedAtBegin, params.updatedAtEnd] = timeRange.value
        params.startDate = null
        params.endDate = null
      }
    }
    return params
  }
  const withdrawDownload = cb => {
    Vue.prototype.$showLoading()
    const params = getDownloadParams()
    $store.dispatch('withdraw/download', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code == '200') {
        cb()
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const handlerFormatData = data => {
    if (!data) return
    const options = []
    data.forEach(obj => {
      const o = {}
      // eslint-disable-next-line prefer-destructuring
      o.value = Object.keys(obj)[0]
      o.title = obj[o.value]
      options.push(o)
    })
    // eslint-disable-next-line consistent-return
    return options
  }
  // if (!store.state.withdraw.modeOptions) {
  //   fetchModeOption()
  // } else {
  //   modeOptions.value = store.state.withdraw.modeOptions
  // }
  if (!$store.state.withdraw.withdrawChannelOption || $store.state.withdraw.withdrawChannelOption.length === 0) {
    fetchChannelOption()
  } else {
    withdrawChannelOption.value = $store.state.withdraw.withdrawChannelOption || []
  }
  if (!$store.state.withdraw.withdrawStatusOption) {
    fetchWalletStatusOption()
  } else {
    withdrawStatusOption.value = $store.state.withdraw.withdrawStatusOption
  }
  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    refWithdrawListTable,

    modeOptions,
    withdrawChannelOption,
    withdrawStatusOption,

    searchKey,
    mode,
    withdrawStatus,
    withdrawChannel,
    timeRange,
    timeRangeDefault,
    timezone,
    currency,
    timeType,

    withdrawList,
    fetchWithdrawList,
    withdrawDownload,
  }
}
